import { useState, useEffect } from 'react';
import axios from 'axios';

function useCalendarEvents(date, setLoading) {
  const [calendarEvents, setCalendarEvents] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios.get(
      'api/calendar_entries',
      {
        params: {
           date: date._d
         }
      }
    ).then(res => {
        setCalendarEvents(res.data);
        setLoading(false);
      }
    )
  }, [date]);

  return calendarEvents;
}

export default useCalendarEvents;