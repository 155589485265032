import React, { useState, useEffect } from "react";

function FormFields(props) {
  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    props.setEventParams({
      ...props.eventParams,
      [name]: value,
    });
  }

  return (
    <form name="sentMessage" className="form-fields">
      <div className="row">
        <div className="control-group col-md-4 mt-4 mt-sm-0">
          <div className="form-group controls mb-0 pb-2">
            <input
              className="form-control"
              name="name"
              type="text"
              required={true}
              value={props.eventParams.name || ""}
              onChange={handleInputChange}
            />
            <span className="floating-label">Name</span>
          </div>
        </div>

        <div className="control-group col-md-4 mt-4 mt-sm-0">
          <div className="form-group controls mb-0 pb-2">
            <input
              className="form-control"
              name="email"
              type="text"
              required={true}
              value={props.eventParams.email || ""}
              onChange={handleInputChange}
            />
            <span className="floating-label">Email</span>
          </div>
        </div>

        <div className="control-group col-md-4 mt-4 mt-sm-0">
          <div className="form-group controls mb-0 pb-2">
            <input
              className="form-control"
              name="phone"
              type="text"
              required={true}
              value={props.eventParams.phone || ""}
              onChange={handleInputChange}
            />
            <span className="floating-label">Phone Number</span>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="control-group col-12">
          <div className="form-group controls mb-3 pb-2">
            <textarea
              className="form-control"
              name="message"
              required={true}
              value={props.eventParams.message || ""}
              onChange={handleInputChange}
            />
            <span className="floating-label">
              Tell us why you are contacting Pelloux Law
            </span>
          </div>
        </div>
      </div>
    </form>
  );
}

export default FormFields;
