import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import TimeSlotSelector from "./TimeSlotSelector";
import FormFields from "./FormFields";
import axios from "axios";
import moment from "moment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Scheduler() {
  const [loading, setLoading] = useState(false);
  const [createResponse, setCreateResponse] = useState(null);
  const [timeSlot, setTimeSlot] = useState(moment()._d);
  const [eventParams, setEventParams] = useState({});
  const [payload, setPayload] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);

  function isPayloadValid() {
    return !!(
      payload.name &&
      payload.email &&
      payload.phone &&
      payload.message &&
      payload.start_datetime &&
      termsAccepted
    );
  }

  function submitPayload() {
    setLoading(true);
    axios
      .post("api/calendar_entries", { calendar_entry: payload })
      .then((res) => {
        setCreateResponse(res);
        setLoading(false);
      })
      .catch((error) => {
        setCreateResponse(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    setPayload({
      ...payload,
      ...eventParams,
      start_datetime: timeSlot,
    });
  }, [timeSlot, eventParams]);

  if (loading) {
    return (
      <div className="text-center">
        <Loader type="ThreeDots" color="#2b4662" height={100} width={100} />
      </div>
    );
  }

  if (createResponse) {
    if (createResponse.status === 200) {
      return (
        <div className="message success-message">
          <p>
            Success! Your request has been received and our office will contact you to confirm your appointment.
          </p>
        </div>
      );
    } else {
      return (
        <div className="message error-message">
          <p>
            Oh no! Something went wrong while trying to book your appointment.
            Please use one of these other forms of contact to get in touch:
          </p>
          <ul>
            <li>Phone: 720-353-4053</li>
            <li>Email: info@pellouxlaw.com</li>
          </ul>
        </div>
      );
    }
  }

  return (
    <div className="container scheduler">
      <FormFields eventParams={eventParams} setEventParams={setEventParams} />
      <TimeSlotSelector onSelection={(timeSlot) => setTimeSlot(timeSlot)} />

      <div className="row submit-footer">
        <div className="col-6">
          <label>
            <input
              className="mr-2"
              name="termsAccepted"
              type="checkbox"
              checked={termsAccepted}
              onChange={(event) => setTermsAccepted(event.target.checked)}
            />
            By checking this box, I agree to the terms of the{" "}
            <a href="/disclaimer" target="_blank">
              disclaimer
            </a>
          </label>
        </div>

        <div className="col-6">
          <button
            className="btn btn-primary"
            disabled={!isPayloadValid()}
            onClick={submitPayload}
          >
            Schedule Consultation
          </button>
        </div>
      </div>
    </div>
  );
}

export default Scheduler;
