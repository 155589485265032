import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import useCalendarEvents from "./useCalendarEvents";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function TimeSlotSelector(props) {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(moment());
  const [days, setDays] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState(7);
  const [numberOfSlots, setNumberOfSlots] = useState(10);
  const [timeSlotGroups, setTimeSlotGroups] = useState([]);
  const calendarEvents = useCalendarEvents(date, setLoading);

  function moveDateForward(e) {
    e.preventDefault();
    const newDate = moment(date.add(numberOfDays, "days"));
    setDate(newDate);
  }

  function moveDateBackward(e) {
    e.preventDefault();
    const newDate = moment(date.subtract(numberOfDays, "days"));
    setDate(newDate);
  }

  function hasConflict(datetime) {
    const conflicts = calendarEvents.filter((calendarEvent) => {
      const guardBefore = moment(calendarEvent.start_at).subtract(
        30,
        "minutes"
      );
      const guardAfter = moment(calendarEvent.end_at).add(30, "minutes");
      return datetime.isBetween(guardBefore, guardAfter);
    });

    return conflicts.length > 0;
  }

  function tooSoon(datetime) {
    return (
      datetime.isBefore(moment().add(2, "day"), "day") ||
      (date.day() === 5 &&
        datetime.isBefore(moment().add(4, "days"), "day")) ||
      (date.day() === 6 &&
        datetime.isBefore(moment().add(3, "days"), "day"))
    );
  }

  function disabled(datetime) {
    return (
      [0, 6].includes(moment(datetime).day()) ||
      tooSoon(datetime) ||
      hasConflict(datetime)
    );
  }

  function selectTimeSlot(selectedGroupIndex, selectedTimeSlotIndex, timeSlot) {
    if (timeSlot.disabled) {
      return;
    }

    const tempTimeSlotGroups = timeSlotGroups.map((timeSlotGroup, groupIndex) =>
      timeSlotGroup.map((timeSlot, timeSlotIndex) => {
        return {
          ...timeSlot,
          selected:
            groupIndex === selectedGroupIndex &&
            timeSlotIndex === selectedTimeSlotIndex,
        };
      })
    );

    setTimeSlotGroups(tempTimeSlotGroups);
    props.onSelection(timeSlot.datetime._d);
  }

  useEffect(() => {
    const start = moment(date);
    const end = moment(start.add(numberOfDays, "days"));
    const tempDays = [];
    for (var m = moment(date); m.isBefore(end); m.add(1, "days")) {
      tempDays.push(moment(m));
    }
    setDays(tempDays);
  }, [date]);

  useEffect(() => {
    const timeSlotIndexes = [...Array(numberOfSlots).keys()];
    const timeSlotOffset = 8;
    const tempTimeSlotGroups = timeSlotIndexes.map((timeSlotIndex) =>
      days.map((day) => {
        const datetime = moment(
          day
            .hour(timeSlotIndex + timeSlotOffset)
            .minute(0)
            .second(0)
        );
        const timeSlot = {
          datetime: datetime,
          disabled: disabled(datetime),
        };
        return timeSlot;
      })
    );

    setTimeSlotGroups(tempTimeSlotGroups);
  }, [calendarEvents]);

  if (loading) {
    return (
      <div className="text-center">
        <Loader type="ThreeDots" color="#2b4662" height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="time-slot-selector">
      <div className="row text-center">
        <div className="col-4">
          <div className="btn btn-primary" onClick={moveDateBackward}>
            <i className="fa fa-arrow-left" />
          </div>
        </div>
        <div className="col-4">
          <div className="btn btn-primary" onClick={() => setDate(moment())}>
            Current Week
          </div>
        </div>
        <div className="col-4">
          <div className="btn btn-primary" onClick={moveDateForward}>
            <i className="fa fa-arrow-right" />
          </div>
        </div>
      </div>

      <table className="mt-5 table table-responsive table-borderless appointments-table">
        <thead>
          <tr>
            {days.map((day) => (
              <th key={day._d}>{day.format("dddd, MMMM Do YYYY")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {timeSlotGroups.map((timeSlotGroup, groupIndex) => (
            <tr key={`group-${groupIndex}`}>
              {timeSlotGroup.map((timeSlot, timeSlotIndex) => (
                <td key={timeSlot.datetime._d}>
                  <div
                    onClick={() =>
                      selectTimeSlot(groupIndex, timeSlotIndex, timeSlot)
                    }
                    className={
                      "time-slot" +
                      (timeSlot.disabled ? " disabled" : "") +
                      (timeSlot.selected ? " selected" : "")
                    }
                  >
                    {moment(timeSlot.datetime, "HH:mm:ss").format("h A")}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TimeSlotSelector;
